.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

div.cmsCard {
  transition: all 0.2s ease-in-out;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

div.cmsCard:hover {
  transform: scale(1.1);
  cursor: pointer;
  background-color: #7bd6e4;
}

.cmsCardLink {
  color: inherit;
}

.cmsCardLink:hover {
  color: inherit;
  text-decoration: inherit;
}

span.specialProperty {
  padding: 0.5em;
}

/*.resultListItem {*/
/*  display: flex;*/
/*  justify-content: space-between;*/
/*  -webkit-touch-callout: none;*/
/*  -webkit-user-select: none;*/
/*  -khtml-user-select: none;*/
/*  -moz-user-select: none;*/
/*  -ms-user-select: none;*/
/*  user-select: none;*/
/*}*/

span.lastUpdated {
  display: inline-flex;
  align-items: center;
  margin-top: 1em;
  font-size: smaller;
}

.cmsTableNameColumn {
  font-size: "1.1em";
  font-style: oblique;
  font-weight: bold;
}

.optionCard {
  transition: all 0.2s ease-in-out;
  width: 20rem;
  padding: 20px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.optionCard:hover {
  transform: scale(1.1);
  cursor: pointer;
  background-color: #7bd6e4;
}
