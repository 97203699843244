/* Main colors */
$primaryColor: #975cf6; // #AD5389
$secondaryColor: #35087e; // #3C1053
$lightBackground: #f4f5f7;

/* Gradient colors */
$cardColor1: (first: #5a9cfe, second: #073984);
$cardColor2: (first: #975cf6, second: #35087e);
$cardColor3: (first: #e374ab, second: #6f1b45);
$cardColor4: (first: #e77681, second: #721c24);
$cardColor5: (first: #67dab8, second: #11694f);
$cardColor6: (first: #fea75f, second: #84420a);
$cardColors: ($cardColor1, $cardColor2, $cardColor3, $cardColor4, $cardColor5, $cardColor6);

/* Main fonts */
$primaryFont: 'Open Sans', sans-serif;
$secondaryFont: 'Alata', sans-serif;

body {
  font-family: $primaryFont;
}

a {
  color: #000;

  &:hover {
    color: $primaryColor;
    text-decoration: none;
  }
}

.card {
  box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07) !important;
  border: 0;

  &:hover {
    box-shadow: 0 0.5rem 2rem 0 rgba(31, 45, 65, 0.25) !important;
  }
}

#root {
  transition: all 0.3s;
}

.sidebar-open {
  #root {
    margin-left: 30rem;
    @media only screen and (max-width: 768px) {
      margin-left: 0 !important;
    }
  }

  .p-sidebar {
    box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07) !important;
  }
}

nav {
  padding: 20px 0;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-family: $secondaryFont;

  .logo {
    font-size: 1.2rem;
    color: $primaryColor;
    font-weight: bold;
    background: linear-gradient(to right, $primaryColor, $secondaryColor);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: left;
    span {
      vertical-align: 2px;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline;
      margin-left: 20px;

      a {
        color: #000;

        &:hover, &.active {
          color: $primaryColor;
          text-decoration: none;
        }
      }
    }
  }
}

header {
  height: 600px;
  background: url("../images/background.svg"), linear-gradient(to right, $primaryColor, $secondaryColor);
  color: #fff;
  font-family: $secondaryFont;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 3;


  h1 {
    font-weight: bold;
    margin-bottom: 40px;
  }

  &.small {
    height: 150px;

    h1 {
      margin-bottom: 0;
    }

  }

  .svg-line {
    position: absolute;
    bottom: 10px;
    width: 800px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0.9;
    stroke-width: 2px;

    @media (max-width: 930px) {
      display: none;
    }
  }
}

section#filter-menu {
  background: #fff;
  height: 50px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .08);
  box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07) !important;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;

  a {
    color: #000;
    position: absolute;

    top: 50%;
    transform: translate(0%, -50%);

    &:hover {
      color: $primaryColor;
      text-decoration: none;
    }
  }

  .filters {
    font-family: $secondaryFont;
    text-transform: uppercase;
    font-size: .8rem;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .filter-switch, .list-card-switch {
      position: absolute;

      &:hover {
        color: $primaryColor;
      }

    }

    .filter-switch {
      left: 15px;

      a {
        width: 100px;
        left: 0px;
      }
    }

    .list-card-switch {
      right: 15px;

      a {
        width: 100px;
        right: 0px;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        display: inline-flex;
        padding: 0 20px;
        height: 50px;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid transparent;

        &:hover, &.active {
          border-bottom: 2px solid $primaryColor;
          color: $primaryColor;
        }

        @each $cardColor in $cardColors {
          $i: index($cardColors, $cardColor);
          &.card-color-#{$i} {
            &:hover {
              color: map-get($cardColor, first);
              border-bottom: 2px solid map-get($cardColor, first);
            }
          }
        }
      }
    }
  }
}

.p-sidebar {
  background: #fff;
  font-size: .8rem;
  width: 30rem !important;
  @media only screen and (max-width: 768px) {
    width: 100vw !important;
  }
  overflow: auto;

  .p-sidebar-close {
    outline: 0 !important;
    user-select: none !important;
    margin-top: 10px;

    &:hover {
      color: $primaryColor;
    }
  }

  .p-card {
    box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07) !important;
    border: 0;
    background: $lightBackground;

    &:hover {
      box-shadow: 0 0.5rem 2rem 0 rgba(31, 45, 65, 0.25) !important;
    }

    .p-card-body {
      padding: 0;
      position: relative;

      .p-card-title {
        background: $primaryColor;
        border-top-left-radius: .24rem;
        border-top-right-radius: .24rem;
        color: #fff;
        margin: 0;
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: bold;
        padding: .8rem;
      }

      .description {
        position: absolute;
        top: 1rem;
        right: 1rem;
        color: #fff;
        cursor: pointer;
      }

      .p-card-content {
        padding: 10px 0;
        background: #fff;

        .score-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5px 20px;

          &:hover {
            background: #f4f4f4;
          }

          .score-field-name {
            font-weight: bold;
            cursor: help;
          }

        }

        .score-checkboxes {
          display: flex;
          flex-direction: column;
          //min-width: 120px;
          input {
            display: none;
          }

          input + label {
            position: relative;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 20px;

            &:after {
              content: '';
              left: 0;
              position: absolute;
              width: 1rem;
              height: 1rem;
              border-radius: .1rem;
              border: 1px solid $secondaryColor;
              box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07) !important;
            }
          }

          input:checked + label {
            &:after {
              content: '';
              border: 1px solid $secondaryColor;
              background: $primaryColor;
            }

            &:before {
              content: "\f00c";
              font-family: 'Font Awesome 5 Free';
              font-weight: 900;
              color: #fff;
              font-size: 0.5rem;
              left: 4px;
              position: absolute;
              z-index: 1;
            }
          }
        }
      }
    }
  }


  .switch-toggle {
    font-size: 0.8rem;

    p {
      margin-bottom: 5px;
      font-family: $secondaryFont;
      text-transform: uppercase;
    }

    label {
      background: #fff;
      color: $primaryColor !important;

      min-width: 30px;
      min-height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07) !important;
      margin-top: 5px;

      &:first-of-type {
        border-top-left-radius: .24rem;
        border-bottom-left-radius: .24rem;
      }

      &:last-of-type {
        border-top-right-radius: .24rem;
        border-bottom-right-radius: .24rem;
      }
    }

    input {
      position: absolute;
      opacity: 0;
    }

    input + label {
      padding: 7px;
      color: #fff;
      display: inline-flex;
      cursor: pointer;
    }

    input:checked + label {
      background: $secondaryColor;
      color: #fff !important;
      box-shadow: 0 0.5rem 2rem 0 rgba(31, 45, 65, 0.25) !important;
    }
  }
}

main {
  transition: margin-left .5s;
  position: relative;


  padding-top: 50px;
  background: $lightBackground;
  min-height: 500px;

  .cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    margin: 0 -20px;

    .mix {
      display: inline-flex;
      vertical-align: top;
      @media screen and (min-width: 768px) {
        width: calc(100% / 2);
      }
      @media screen and (min-width: 1024px) {
        width: calc(100% / 3);
      }
    }

    .card {
      width: 100%;
      margin: 20px;
      padding: 3rem 1.5rem;
      border: 0;
      transition: all .15s ease;
      border-radius: .25rem !important;
      cursor: pointer;

      &:hover {
        transform: translateY(-10px);
        transition: all .15s ease;
      }

      h2 {
        font-family: $secondaryFont;
        color: $primaryColor;
        background: linear-gradient(to right, $primaryColor, $secondaryColor);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 20px;
      }

      &.card-deactivated{
        opacity: 0.2;
      }

      @each $cardColor in $cardColors {
        $i: index($cardColors, $cardColor);
        &.card-color-#{$i} {
          h2 {
            background: linear-gradient(to right, map-get($cardColor, first), map-get($cardColor, second));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .progress-bar.bg-info {
            background-color: map-get($cardColor, first)!important;
            animation: none;
          }
        }
      }

      ul {
        list-style: none;
        padding: 0;
        li {
          font-size: 0.8rem;
          margin-bottom: 5px;
        }
      }

      .github-corner {
        &:hover .octo-arm {
          transform-origin: 130px 106px;
          animation: octocat-wave 560ms ease-in-out
        }

        svg {
          fill: #24292e;
          color: #fff;
          position: absolute;
          top: 0;
          border: 0;
          right: 0;
          border-top-right-radius: .25rem !important;;
        }

        @keyframes octocat-wave {
          0%, 100% {
            transform: rotate(0)
          }
          20%, 60% {
            transform: rotate(-25deg)
          }
          40%, 80% {
            transform: rotate(10deg)
          }
        }

        @media (max-width: 500px) {
          &:hover .octo-arm {
            animation: none
          }
          .octo-arm {
            animation: octocat-wave 560ms ease-in-out
          }
        }
      }

      &.card-line:after {
        content: '';
        height: 4px;
        width: 100%;
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        border-bottom-right-radius: .25rem !important;
        border-bottom-left-radius: .25rem !important;
        background: $primaryColor;
      }

      @each $cardColor in $cardColors {
        $i: index($cardColors, $cardColor);
        &.card-color-#{$i} {
          &:after {
            background: map-get($cardColor, first);
          }
        }
      }
    }
  }
}

section#list-view, section#detail-view {

  .card {
    .p-datatable-frozen-view {
      border-top-left-radius: .24rem;
      background: #ac7df8;
    }

    .progress-bar.bg-info {
      animation: none;
      background-color: $primaryColor !important;
    }

    .p-datatable-unfrozen-view {
      background: $primaryColor;
      border-top-right-radius: .24rem;
    }

    table {
      font-size: .8rem;

      thead {
        color: #fff;

        tr {
          th {
            width: 250px;
            height: 50px;
            padding: 0 10px;
            vertical-align: middle;

            &:first-child, &:nth-child(2) {
              text-align: left;
            }
          }
        }

      }

      tbody {
        background: #fff;

        tr {
          &:hover {
            background: #f4f4f4;
          }
          &:first-child {
            padding-top: 5px;
          }

          td {
            width: 250px;
            padding: 10px;

            &:first-child, &:nth-child(2) {
              text-align: left;
            }

            &.cmsTableNameColumn {
              background: #f4f4f4;
            }

            a {
              color: $primaryColor;

              &:hover {
                color: $secondaryColor;
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }

}

section#detail-view {
  h5 {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: bold;
  }

  .card {
    &.info-box {

    }

    .p-datatable {
      background: $primaryColor;
      border-top-left-radius: .24rem !important;
      border-top-right-radius: .24rem !important;
    }

    table {
      width: 100%;
      tbody {
        background: #fff;

        tr {
          &:hover {
            background: #f4f4f4;
          }
        }
      }
    }
  }
}

footer {
  background: $secondaryColor;
  color: #fff;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  a {
    color: #fff;

    &:hover {
      color: #fff;
    }
  }
}

#main-filter-button {
  color: #00000091;
  border-bottom: 4px solid #975cf6 !important;
  font-weight: bold;
}
